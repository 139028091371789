export const urlConstants = {
    APP_URL: 'https://app.tecmow.com',
    SIGNUP_PAGE: 'https://app.tecmow.com/signup-page',
    LOGIN_PAGE: 'https://app.tecmow.com/login-page',
    ORDER_SERVICE_URL: 'https://app.tecmow.com',
    CALCULATION_PAGE: 'https://app.tecmow.com/calculation-page',
    CONTACT_US: 'https://8ykuc54eik.execute-api.us-east-1.amazonaws.com/dev/contact/post-mail', //backend url api
    BLOG_PAGE: 'http://blog.tecmow.com',
    PRICING_PAGE: 'https://tecmow.com/pricing'

};
