import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// core components
import Header from "components/Header/Header.jsx";
import Footer from "components/Footer/Footer.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Button from "components/CustomButtons/Button.jsx";
import HeaderLinks from "components/Header/HeaderLinks.jsx";
import Parallax from "components/Parallax/Parallax.jsx";
import landingPageStyle from "assets/jss/material-kit-pro-react/views/landingPageStyle.jsx";
// Sections for this page
import SectionProduct from "./Sections/SectionProduct.jsx";
import {urlConstants} from '../../constants';
import stepOne from "assets/img/step-one.svg";
import stepTwo from "assets/img/step-two.svg";
import stepThree from "assets/img/step-three.svg"
import brandImage from "../../assets/img/White Font Tec Mow.svg";
import CookieConsent from "react-cookie-consent";
import ContactUsPage from "../ContactUsPage/ContactUsPage";
import SectionTeam from "./SectionTeamCustom";

class LandingPage extends React.Component {

    constructor(props) {
        super(props)
        this.myRef = React.createRef();
    }

    scrollToMyRef = () => window.scrollTo({
        behavior: 'smooth',
        top: this.myRef.current.offsetTop + this.myRef.current.offsetHeight
    })

    componentDidMount() {
        window.scrollTo(0, 0);
        document.body.scrollTop = 0;
    }

    render() {
        const {classes, ...rest} = this.props;
        return (
            <div>
                <CookieConsent
                    location="bottom"
                    buttonText="Accept Cookies"
                    cookieName="myAwesomeCookieName2"
                    style={{
                        background: "#4caf50",
                        alignItems: 'center'
                    }}
                    buttonStyle={{
                        background: 'white',
                        color: '#4caf50',
                        fontSize: "15px",
                        borderRadius: '30px',
                        width: '150px',
                        height: '50px'
                    }}
                    expires={150}
                >
                    Hey there! <br/>
                    We use cookies to personalise content and ads, to provide social media features, and to analyse our
                    traffic.
                    We also share information about your use of our site with our social media, advertising, and
                    analytics partners.
                </CookieConsent>
                <Header
                    color="transparent"
                    brand="Material Kit PRO React"
                    links={<HeaderLinks dropdownHoverColor="info"/>}
                    fixed
                    changeColorOnScroll={{
                        height: 300,
                        color: "info"
                    }}
                    {...rest}
                />
                <Parallax image={require("assets/img/landing-page4.jpeg")} filter="dark">
                    <div className={classes.container}>
                        <GridContainer>
                            <GridItem xs={12} sm={12} md={12} className={`${classes.marginAuto} ${classes.textCenter}`}>

                                <h4 className={`${classes.landingTitle} ${classes.marginBottom_30}`}>
                                    FIND OUT THE SECRET TO BIRMINGHAM'S BEST GARDENS
                                </h4>
                                <img sm={0} src={brandImage} alt="Smiley face" className={`${classes.logo}`}/>
                                <div></div>
                                <Button
                                    color="success"
                                    size="lg"
                                    href={urlConstants.LOGIN_PAGE}
                                    rel="noopener noreferrer"
                                >
                                    BOOK A GARDENER
                                </Button>
                                {' '}
                                <Button
                                    color="white"
                                    size="lg"
                                    rel="noopener noreferrer"
                                    onClick={() => this.scrollToMyRef()}
                                >
                                    Get a Quote
                                </Button>
                            </GridItem>
                        </GridContainer>
                    </div>
                </Parallax>
                <div className={classNames(classes.main, classes.mainRaised)}>

                    <div className={classes.container}>
                        <SectionTeam/>
                    </div>
                    <div className={classes.container}>
                        <SectionProduct/>

                        <GridItem xs={12} sm={12} md={12}>
                            <div className={`
                                ${classes.textCenter} 
                                ${classes.marginTop50} 
                                ${classes.marginBottom50} 
                                ${classes.font30}
                                ${classes.fontBlack} 
                                ${classes.headerStyle}`
                            }>How it works
                            </div>
                            <GridItem xs={12} sm={12} md={4}
                                      className={`
                                        ${classes.displayInlineBlock} 
                                        ${classes.marginTop15} 
                                        ${classes.marginBottom15}
                                        ${classes.paddingNull}`
                                      }>
                                <div
                                    className={`
                                        ${classes.displayInlineBlock}
                                        ${classes.width100Percent}
                                        ${classes.verticalAlignTop}
                                        ${classes.paddingRight5}
                                        ${classes.responsiveFont}`
                                    }
                                >
                                    <img src={stepOne} alt="step-one"
                                         className={
                                             `${classes.maxWidth100} ${classes.positionRelative} `
                                         }
                                    />

                                </div>
                                <div className={`
                                    ${classes.displayInlineBlock}
                                    ${classes.width100Percent}
                                    ${classes.textCenter}
                                    ${classes.marginTop15}
                                    ${classes.verticalAlignTop}
                                    ${classes.fontGrey}
                                    ${classes.responsiveFont}`
                                }>
                                    <span><a href={urlConstants.SIGNUP_PAGE}>Sign up</a> in order to use the booking system</span>
                                </div>
                            </GridItem>
                            <GridItem xs={12} sm={12} md={4}
                                      className={`
                                        ${classes.displayInlineBlock} 
                                        ${classes.marginTop15} 
                                        ${classes.marginBottom15}
                                        ${classes.paddingNull}`
                                      }>
                                <div
                                    className={`
                                        ${classes.displayInlineBlock}
                                        ${classes.width100Percent}
                                        ${classes.verticalAlignTop}
                                        ${classes.paddingRight5}`
                                    }
                                >
                                    <img src={stepTwo} alt="step-two"
                                         className={`
                                            ${classes.maxWidth100}
                                            ${classes.positionRelative}`
                                         }
                                    />

                                </div>
                                <div className={`
                                    ${classes.displayInlineBlock}
                                    ${classes.width100Percent}
                                    ${classes.textCenter}
                                    ${classes.marginTop15}
                                    ${classes.verticalAlignTop}
                                    ${classes.fontGrey}
                                    ${classes.responsiveFont}`
                                }>
                                    <span>Let us know a day we can come (it helps us a lot if you can be flexible)</span>
                                </div>
                            </GridItem>
                            <GridItem xs={12} sm={12} md={4}
                                      className={`
                                        ${classes.displayInlineBlock} 
                                        ${classes.marginTop15} 
                                        ${classes.marginBottom15}
                                        ${classes.paddingNull}`
                                      }>
                                <div
                                    className={`
                                        ${classes.displayInlineBlock}
                                        ${classes.width100Percent}
                                        ${classes.verticalAlignTop}
                                        ${classes.paddingRight5}`
                                    }
                                >
                                    <img src={stepThree} alt="step-three"
                                         className={`
                                            ${classes.maxWidth100}
                                            ${classes.positionRelative}`
                                         }
                                    />
                                </div>
                                <div className={`
                                    ${classes.displayInlineBlock}
                                    ${classes.width100Percent}
                                    ${classes.textCenter}
                                    ${classes.marginTop15}
                                    ${classes.verticalAlignTop}
                                    ${classes.fontGrey}
                                    ${classes.responsiveFont}`
                                }>
                                    <span>Let the professionals do the work</span>
                                </div>
                            </GridItem>
                        </GridItem>
                    </div>
                    <div ref={this.myRef}>
                        <ContactUsPage/>
                    </div>
                </div>
                <Footer/>
            </div>
        );
    }
}

export default withStyles(landingPageStyle)(LandingPage);
