export default {
  opacity: {
    opacity: 0.6
  },
    garden: {
        position: 'relative',
        width: '100%',
        width: '300px',
        overflow: 'hidden',
        boxShadow: '0 5px 15px -8px rgba(0, 0, 0, 0.24), 0 8px 10px -5px rgba(0, 0, 0, 0.2)',
        borderRadius: '10px'
      },
      gardenActive: {
        boxShadow: 'rgb(105 133 107) 0px 6px 24px 1px, rgb(102 130 104) 0px 8px 10px -5px'
      },
      gardenButtonSection: {
        textAlign: 'right', paddingBottom: '30px'
      },
      imageGarden: {
        objectFit: 'fill',
        width: '100%',
      }
}