import {
  mrAuto,
  mlAuto,
  cardTitle,
  whiteColor,
  infoColor,
  hexToRgb,
  main
} from "assets/jss/material-kit-pro-react.jsx";

const pricingStyle = {
  main: {
    ...main
  },
  mrAuto,
  mlAuto,
  cardTitle,
  cardTitleWhite: {
    ...cardTitle,
    color: whiteColor + " !important",
    "& small": {
      color: "rgba(" + hexToRgb(whiteColor) + ",0.8)!important"
    }
  },
  textCenter: {
    textAlign: "center"
  },
  pricingSection: {
    padding: "20px 0px"
  },
  textInfo: {
    color: infoColor[0] + " !important"
  },
  opacity: {
    opacity: 0.6
  },
  fixContainer: {
    "@media only screen > 900px and screen < 1000px": {
      display: 'flex !important',
      'flex-direction': 'row',
      'align-items': 'center',
      '& > div': {
        'max-width': '100%',
        'flex-basis': '100%'
      }
    }
  },
  garden: {
    position: 'relative',
    width: '100%',
    width: '300px',
    overflow: 'hidden',
    boxShadow: '0 5px 15px -8px rgba(0, 0, 0, 0.24), 0 8px 10px -5px rgba(0, 0, 0, 0.2)',
    borderRadius: '10px'
  },
  gardenActive: {
    boxShadow: 'rgb(105 133 107) 0px 6px 24px 1px, rgb(102 130 104) 0px 8px 10px -5px'
  },
  gardenCard: {
    "@media only screen and (max-width: 1050px)": {
        display: 'flex',
        justifyContent: 'center'
    },
  },
  gardenCardBody: {
    "@media only screen and (max-width: 1050px)": {
        display: 'inline-block',
        flex: 'initial'
    },
  },
    
  gardenButtonSection: {
    textAlign: 'right', paddingBottom: '30px',
    "@media only screen and (max-width: 1050px)": {
      textAlign: 'center'
    },
  },
  fixedMobileButtons: {
    "@media only screen and (max-width: 960px)": {
      textAlign: 'center',
      position: 'fixed',
      bottom: '0',
      left: '0',
      width: '100%',
      margin: '0'
    },
  },
  imageGarden: {
    objectFit: 'fill',
    width: '100%',
  },
  price: {
    boxShadow: '0 5px 15px -8px rgba(0, 0, 0, 0.24), 0 8px 10px -5px rgba(0, 0, 0, 0.2)',
    transition: '0.2s linear',
    cursor: 'pointer',
    background: 'whitesmoke',
    '&:hover': {
      boxShadow: 'rgb(200 202 200) 0px 6px 19px 1px, rgb(113 121 114) 0px 8px 10px -5px'
    }
  },
  priceActive: {
    boxShadow: 'rgb(200 202 200) 0px 6px 19px 1px, rgb(113 121 114) 0px 8px 10px -5px'
  },
};

export default pricingStyle;
