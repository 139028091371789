import {
  mrAuto,
  mlAuto,
  title,
  description,
  cardTitle,
  grayColor, successColor
} from "assets/jss/material-kit-pro-react.jsx";

const teamStyle = {
  mrAuto,
  mlAuto,
  title,
  description: {
    ...description,
    marginBottom: "80px"
  },
  cardTitle,
  cardDescription: {
    color: grayColor[0]
  },
  team: {
    padding: "80px 0px 0"
  },
  textCenter: {
    textAlign: "center!important"
  },
  textName: {
    color: grayColor[0]
  },
  titleBackground: {
    color: grayColor[7],
    background: 'transparent !important',
    fontWeight: "700 !important",
    fontSize: "18px"
  },
  iconGreenColor: {
    width: "28px",
    height: "28px"
  },
  skillsBlock: {
    display: 'flex',
    padding: "0 0 0 30px"
  },
  img: {
    width: "100%",
    height: "auto"
  },
  starBlock: {
    display: 'flex',
    justifyContent: "center",
    marginBottom: 15
  },
  textMuted: {
    color: grayColor[7] + " !important"
  },
  textDescrib: {
    color: grayColor[8],
    fontSize: "14px",
    fontWeight: 300,
    textAlign: "center !important"
  },
  textLocate: {
    color: grayColor[7],
    fontSize: "14px",
    fontWeight: 500,
    textAlign: "center !important"
  },
  justifyContent: {
    justifyContent: "center!important"
  }
};

export default teamStyle;
