import large from "assets/img/gardens/large.jpeg";
import medium from "assets/img/gardens/medium.jpeg";
import small from "assets/img/gardens/small.jpeg";

const GARDENS = {
    small: {
        name: 'Small',
        description: 'Less than 100 sqm',
        src: small,
        prices: {
            standard: {
                currency: '£',
                cost: '50',
                description: 'per month – 12 months'
            },
            premium: {
                currency: '£',
                cost: '25',
                description: 'per week'
            },
            delux: {
                currency: '£',
                cost: '50',
                description: 'per week'
            }
        }
    },
    medium: {
        name: 'Medium',
        description: '100 - 400 sqm',
        src: medium,
        prices: {
            standard: {
                currency: '£',
                cost: '75',
                description: 'per month'
            },
            premium: {
                currency: '£',
                cost: '37.50',
                description: 'per week'
            },
            delux: {
                currency: '£',
                cost: '75',
                description: 'per week'
            }
        }
    },
    large: {
        name: 'Large',
        description: '500 sqm',
        src: large,
        prices: {
            standard: {
                currency: '£',
                cost: '110',
                description: 'per month'
            },
            premium: {
                currency: '£',
                cost: '55',
                description: 'per week'
            },
            delux: {
                currency: '£',
                cost: '110',
                description: 'per week'
            }
        }
    }
}
const PRICES = {
    standard: {
        name: 'Standard',
        options: [
            '“I want my garden to look tidy”',
            'Fortnightly visits to mow and strim',
            'Clear weeds from beds and paths',
            'Clear weeds on driveway',
            'Shrubs trimmed',
            'Leaf clearance',
            'Cutting back in Autumn'
        ]
    },
    premium: {
        name: 'Premium',
        options: [
            '“I want my garden to look great”',
            'Weekly visits',
            'Mow and strim',
            'Turn over borders and clear weeds from paths',
            'Shrubs trimmed',
            'Basic lawn treatment (granular feeds and spot spraying weeds)',
            'Cutting back in Autumn',
            'Leaf clearance'
        ]
    },
    delux: {
        name: 'Deluxe',
        options: [
            '“I want my garden to look amazing. Sort the details and I’ll pay the invoice”',
            'Weekly visits to mow and strim',
            'Clear weeds from beds and paths',
            'Clear weeds on driveway',
            'Shrubs trimmed',
            'Leaf clearance',
            'Cutting back in Autumn',
            'Lawn Treatments',
            'Hedge Trimming',
            'Lifting and dividing',
            'Bulb planting'
        ]
    },
}

export {
    GARDENS,
    PRICES
}