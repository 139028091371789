import React from "react";
import axios from 'axios';
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import {urlConstants} from '../../constants';
// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";

import contactUsStyle from "assets/jss/material-kit-pro-react/views/contactUsStyle.jsx";
import SimpleForm from "./Form";
import TransitionsSnackbar from "../LandingPage/snackBar";
import Fade from "@material-ui/core/Fade";
import CircularIndeterminate from "./LinearProgress";

class ContactUsPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      vertical: "top",
      horizontal: "right",
      text: '',
      Transition: Fade,
      loading: false
    };
  }

  handleOpen = (text) => {
    this.setState({
      open: true,
      text: text.data.message,
      loading: false
    })
  }
  handleClose = () => {
    this.setState({
      open: false
    });
  }

  postData(data) {
    this.setState({
      loading: true
    })
    const bodyFormData = new FormData();
    bodyFormData.append('name', data.name);
    bodyFormData.append('postcode', data.postcode);
    bodyFormData.append('house', data.house);
    bodyFormData.append('phone', data.phone);
    bodyFormData.append('description', data.description);
    if(this.props.pricing) {
      bodyFormData.append('price', this.props.pricing.price);
      bodyFormData.append('garden', this.props.pricing.garden);
    }
    if(data.image) {
      for(let x = 0; x < data.image.length; x++) {
        bodyFormData.append('filename[x]', data.image[x] )
      }
    }

    axios({
      method: 'POST',
      url: urlConstants.CONTACT_US,
      data: bodyFormData,
      headers: {'Content-Type': 'multipart/form-data' }
    })
        .then((response) => {
          //handle success
          console.log(response);
          this.handleOpen(response);
          setTimeout(
              () => window.location.href = '/',
              2000
          );
        })
        .catch(function (response) {
          //handle error
          console.log(response);
        });
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
  }
  render() {
    const { classes } = this.props;
    const { vertical, horizontal, open, text, Transition, loading } = this.state;

    return (
      <div>
        <div className={classNames(classes.main, classes.mainRaised)}>
          <div className={classes.contactContent}>
            <div className={classes.container}>
              <h2 className={classNames(classes.title, classes.textCenter)}>Request a quote</h2>
              <GridContainer>
                <GridItem md={6} sm={9}>
                  <p>
                    Send us a message with some pictures and we’ll get back to you with an estimate
                    <br />
                    <br />
                  </p>

                <SimpleForm backToPricing={this.props.pricing ? this.props.pricing.backToPricing : null}  onSubmit={value => this.postData(value)} propsClasses={classes} />
                  { loading && <div className={classes.progress}>
                    <CircularIndeterminate/>
                  </div>}

                </GridItem>
              </GridContainer>
            </div>
          </div>
        </div>
        <TransitionsSnackbar open={open} text={text} close={this.handleClose} transition={Transition} vertical={vertical} horizontal={horizontal} />
      </div>
    );
  }
}

export default withStyles(contactUsStyle)(ContactUsPage);
