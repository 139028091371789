import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import NavPills from "components/NavPills/NavPills.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import Button from "components/CustomButtons/Button.jsx";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

import pricingStyle from "assets/jss/material-kit-pro-react/views/pricingSections/pricingStyle.jsx";

function SectionPricing(props) {
  const { classes, prices, garden, goBack, goToOrder, onSelectPrice, price } = props;
  return (
    <div className={classes.pricingSection}>
      <GridContainer>
      { Object.keys(prices).map((indexPrice, i) => 
        <GridItem xs={12} sm={12} md={4}>
            <Card onClick={() => onSelectPrice(indexPrice)} plain pricing className={
                  classes.price + " " + 
                  (price && (price === indexPrice ? classes.priceActive : classes.opacity))
                  }>
              <CardBody pricing>
                <h6
                  className={classNames(classes.cardCategory, classes.textInfo)}
                >
                  {prices[indexPrice].name}
                </h6>
                <h1 className={classes.cardTitle}>
                    <small>{garden.prices[indexPrice].currency}</small>{garden.prices[indexPrice].cost} <small>{garden.prices[indexPrice].description}</small>
                </h1>
                <ul>
                  { prices[indexPrice].options.map((option) => 
                    <li>
                      {option}
                    </li>
                  )}
                
                </ul>

                {/* <Button href="#pablo" color="primary" round>
                  Get started
                </Button> */}
              </CardBody>
            </Card>
        </GridItem>
      )}
       
      </GridContainer>
        
      <GridContainer className={classes.fixedMobileButtons}>
        <GridItem style={{textAlign: 'left', paddingBottom: '30px'}} xs={6} sm={6} md={6} lg={6} xl={6}>
                <Button onClick={() => goBack()} color="primary" round>Prev</Button>
        </GridItem>
        <GridItem style={{textAlign: 'right', paddingBottom: '30px'}} xs={6} sm={6} md={6} lg={6} xl={6}>
                <Button disabled={!price} onClick={() => goToOrder()} color="primary" round>Next</Button>
        </GridItem>
      </GridContainer>
    </div>
  );
}

export default withStyles(pricingStyle)(SectionPricing);
