import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
// @material-ui/icons
import Favorite from "@material-ui/icons/Favorite";
// core components
import Header from "components/Header/Header.jsx";
import HeaderLinks from "components/Header/HeaderLinks.jsx";
import Parallax from "components/Parallax/Parallax.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Footer from "components/Footer/Footer.jsx";
// sections for this page
import SectionPricing from "views/PricingPage/Sections/SectionPricing.jsx";
import SectionFeatures from "views/PricingPage/Sections/SectionFeatures.jsx";

import pricingStyle from "assets/jss/material-kit-pro-react/views/pricingStyle.jsx";
import SectionGardens from "./Sections/SectionGardens";
import {GARDENS, PRICES} from "../../constants/pricing"
import ContactUsPage from "../ContactUsPage/ContactUsPage";
class PricingPage extends React.Component {
  state = {garden: null, price: null, section: 'garden'}

  componentDidMount() {
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
  }

  onSelectGarden = (garden) => {
    this.setState({garden});
  }

  onSelectPrice = (price) => {
    this.setState({price});
  }

  goToPricing = () => {
    if(this.state.garden) {
      this.setState({section: 'pricing'});
      window.scrollTo(0, 0);
    }
  }

  goToGarden = () => {
    this.setState({section: 'garden'});
    window.scrollTo(0, 0);
  }

  goToOrder = (price) => {
    if(this.state.garden) {
      this.setState({section: 'order'});
      window.scrollTo(0, 0);
    }
  }

  render() {
    const { classes } = this.props;
    return (
      <div>
        <Header
          brand="Material Kit PRO React"
          links={<HeaderLinks dropdownHoverColor="info" />}
          fixed
          color="info"
        />

         
        <div className={classNames(classes.main, classes.mainWithoutBackground)}>
          <div className={classes.container}>
          { (this.state.section === 'garden' || this.state.section === 'pricing') &&
            <div className={classes.brand}>
                <GridContainer justify="center">
                  <GridItem md={8} sm={8}>
                    { this.state.section === 'garden' && 
                      <h2 className={classes.description} style={{textAlign: 'center'}}>
                        How big is your garden?
                      </h2>
                    }
                    <h4 className={classes.mobileHint} style={{textAlign: 'center'}}>
                     { 
                        (this.state.section === 'garden' && ' Tap the photo and click next') || 
                        (this.state.section === 'pricing' && 'Tap the plan and click next') || 
                        'Tap the section and click next'
                      } 
                    </h4>
                  </GridItem>
                </GridContainer>
            </div> 
          }
            {this.state.section === 'garden' && <SectionGardens chooseGarden={this.onSelectGarden} garden={this.state.garden} goToPricing={this.goToPricing} gardens={GARDENS} /> }
            {this.state.section === 'pricing' && <SectionPricing goToOrder={this.goToOrder} goBack={this.goToGarden} onSelectPrice={this.onSelectPrice} garden={GARDENS[this.state.garden]} price={this.state.price} prices={PRICES}/> }
            {this.state.section === 'order' &&
              <div>
                <ContactUsPage pricing={{
                  garden: `${GARDENS[this.state.garden].name} (${GARDENS[this.state.garden].description})`,
                  price: `${GARDENS[this.state.garden].prices[this.state.price].currency} ${GARDENS[this.state.garden].prices[this.state.price].cost} ${GARDENS[this.state.garden].prices[this.state.price].description}`,
                  backToPricing: this.goToPricing
                }}/>
              </div> 
             }
          </div>
        </div>
        <Footer
          content={
            <div>
              <div className={classes.left}>
                <List className={classes.list}>
                  <ListItem className={classes.inlineBlock}>
                    <a
                      href="https://www.creative-tim.com/"
                      className={classes.block}
                    >
                      Creative Tim
                    </a>
                  </ListItem>
                  <ListItem className={classes.inlineBlock}>
                    <a
                      href="https://www.creative-tim.com/presentation"
                      className={classes.block}
                    >
                      About us
                    </a>
                  </ListItem>
                  <ListItem className={classes.inlineBlock}>
                    <a
                      href="//blog.creative-tim.com/"
                      className={classes.block}
                    >
                      Blog
                    </a>
                  </ListItem>
                  <ListItem className={classes.inlineBlock}>
                    <a
                      href="https://www.creative-tim.com/license"
                      className={classes.block}
                    >
                      Licenses
                    </a>
                  </ListItem>
                </List>
              </div>
              <div className={classes.right}>
                &copy; {1900 + new Date().getYear()} , made with{" "}
                <Favorite className={classes.icon} /> by{" "}
                <a href="https://www.creative-tim.com">Creative Tim</a> for a
                better web.
              </div>
            </div>
          }
        />
      </div>
    );
  }
}

export default withStyles(pricingStyle)(PricingPage);
