import {
  container,
  title,
  main,
  mainRaised,
  mlAuto,
  description
} from "assets/jss/material-kit-pro-react.jsx";

const contactUsStyle = {
  main,
  mainRaised,
  title,
  mlAuto,
  description,
  container: {
    ...container,
    maxWidth: "970px !important"
  },
  contactContent: {
    paddingBottom: "40px",
    paddingTop: "40px"
  },
  formBase: {
    marginTop: 40
  },
  formBtn: {
    textAlign: "center",
  },
  formBtnFlex: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  formFileInput: {
    width: "100%",
    height: 45,
    opacity: 0,
  },
  'filename': {
    'overflow': 'hidden',
    'text-overflow': 'ellipsis',
    'white-space': 'nowrap',
    display: 'inline-block',
    'vertical-align': 'middle',
    'max-width': '300px',
    "@media only screen and (max-width: 700px)": {
      'max-width': '250px'
    },
    "@media only screen and (max-width: 500px)": {
      'max-width': '155px'
    },
  },
  formFile: {
    width: "100%",
    height: 50,
    borderBottom: "1px solid rgba(0, 0, 0, 0.32)",
    position: "absolute",
    top: 0,
    left: 0,
    zIndex: -1
  },
  iconSearch: {
    marginRight: 10
  },
  formFileText: {
    position: "absolute",
    bottom: 0,
    left: 0
  },
  formInput: {
    display: "flex",
    flexDirection: "column",
    marginBottom: 20,
    position: "relative"
  },
  formUnderline: {
    borderColor: "red"
  },
  progress: {
    position: 'fixed',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  },
  bigMap: {
    height: "55vh",
    maxHeight: "550px",
    width: "100%",
    display: "block"
  },
  info: {
    paddingBottom: "10px",
    paddingTop: 0
  },
  textCenter: {
    textAlign: "center !important"
  },
  block: {
    color: "inherit",
    padding: "0.9375rem",
    fontWeight: "500",
    fontSize: "12px",
    textTransform: "uppercase",
    borderRadius: "3px",
    textDecoration: "none",
    position: "relative",
    display: "block"
  },
  inlineBlock: {
    display: "inline-block",
    padding: "0px",
    width: "auto"
  },
  list: {
    marginBottom: "0",
    padding: "0",
    marginTop: "0"
  },
  left: {
    float: "left!important",
    display: "block"
  },
  right: {
    padding: "15px 0",
    margin: "0",
    float: "right"
  },
  icon: {
    width: "18px",
    height: "18px",
    top: "3px",
    position: "relative"
  }
};

export default contactUsStyle;
