import React from 'react';
import {withStyles} from "@material-ui/core/styles";
import CircularProgress from '@material-ui/core/CircularProgress';

const styles1 = () => ({
    root: {
        color: '#4caf50'
    },
});

export default function CircularIndeterminate() {

    return (
        <div>
            <MyCustomCircularProgress size={120}/>
        </div>
    );
}

const MyCustomCircularProgress = withStyles(styles1)(CircularProgress);