/* eslint-disable */
import React from "react";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";

import footerStyle from "assets/jss/material-kit-pro-react/components/footerStyle.jsx";
import brandImage from "assets/img/brandImage.png";
import { Link } from 'react-router-dom'

function Footer(props) {
  const { children, content, classes, theme, big, className } = props;
  const themeType =
    theme === "transparent" || theme == undefined ? false : true;
  const footerClasses = classNames({
    [classes.footer]: true,
    [classes[theme]]: themeType,
    [classes.big]: big || children !== undefined,
    [className]: className !== undefined
  });
  const aClasses = classNames({
    [classes.a]: true
  });

  function DownloadPPandTaC(type) {
    if (type === "PP") {      
      downloadFile('/documents/Tec_Mow_Privacy_Policy.docx')
    }
    if (type === "TaC") {      
      downloadFile('/documents/Tec_Mow_B2C_Terms_Final.pdf')
    }
  }
  function downloadFile(filePath) {
    var link = document.createElement('a');
    link.href = filePath;
    link.download = filePath.substr(filePath.lastIndexOf('/') + 1);    
    link.click();    
  }

  return (
    <footer className={footerClasses}>
      <div className={classes.container}>
        {children !== undefined ? (
          <div>
            <div className={classes.content}>{children}</div>
            <hr />
          </div>
        ) : (
            " "
          )}
        {
          <div>
            <div>
              <div className={
                classes.left + " "
                + classes.inlineBlock + " "
                + classes.verticalTop + " "
                + classes.width30 + " "
                + classes.width33Percent + " "
                + classes.width50PercentMedium + " "
                + classes.width100PercentSmall + " "
                + classes.paddingLeft16Small + " "
                + classes.textAlignLeftBig
              }>
                <div className={
                  classes.height34
                }>
                </div>
                <div className={
                  classes.listName + " "
                  + classes.textAlignLeftBig + " "
                  + classes.paddingLeft16 + " "
                  + classes.font700
                }>
                  Company
                    </div>
                <List className={classes.list + " "
                  + classes.inlineUl + " "
                  + classes.verticalTop + " "
                  + classes.smallList
                }>
                  <ListItem className={classes.block}>
                    <Link
                      to="/faq"
                      className={classes.block + " "
                        + classes.leftA + " "
                        + classes.smallA100Width
                      }
                    >
                      FAQ
                          </Link>
                  </ListItem>
                  <ListItem className={classes.block}>
                    <div
                      onClick={() => DownloadPPandTaC('PP')}
                      className={
                        classes.block + " "
                        + classes.leftA + " "
                        + classes.smallA100Width + " "
                        + classes.corsorHovered
                      }>
                      Privacy policy
                    </div>
                  </ListItem>
                  <ListItem className={classes.block}>
                    <div
                      onClick={() => DownloadPPandTaC('TaC')}
                      className={
                        classes.block + " "
                        + classes.leftA + " "
                        + classes.smallA100Width + " "
                        + classes.corsorHovered
                      }>
                      Terms and Conditions
                    </div>
                  </ListItem>
                </List>
              </div>
              <div className={
                classes.left + " "
                + classes.smallWidth100 + " "
                + classes.width33Percent + " "
                + classes.width50PercentMedium + " "
                + classes.width100PercentSmall + " "
                + classes.marginTop0Small + " "
                + classes.textAlignLeftBig
              }>
                <div className={
                  classes.inlineBlock + " "
                  + classes.verticalTop + " "
                  + classes.smallFullWidth
                }>                 
                </div>
              </div>
              <div className={
                classes.left + " "
                + classes.textAlignRight + " "
                + classes.width30 + " "
                + classes.marginTop30 + " "
                + classes.width33Percent + " "
                + classes.width100PercentMedium + " "
                + classes.paddingLeft16Small + " "
                + classes.textAlignRightBig
              }>
                <div className={
                  classes.height34 + " "
                  + classes.displayNoneSmall

                }>
                </div>
                <List className={classes.list + " "
                  + classes.inlineUl
                }>
                  <ListItem className={classes.inlineBlock}>
                    <a href="/"><img src={brandImage} alt="Smiley face" height="70" width="auto"></img></a>
                  </ListItem>
                  <ListItem className={
                    classes.inlineBlock + " "
                    + classes.marginLeft + " "
                    + classes.verticalBottom
                  }>
                    <a
                      href="tel:004401452238064"
                      className={classes.block}
                    >
                      <p className={
                        classes.font16
                      }>
                        01215 822 533</p>
                    </a>
                    <a
                      href="mailto:hello@tecmow.com"
                      className={classes.block}
                    >
                      <p className={
                        classes.font16
                      }>
                        hello@tecmow.com</p>
                    </a>
                  </ListItem>
                </List>
              </div>

              <div className={
                classes.copyrightStyle + " "
                + classes.textAlignleftSmall
              }>
                <div>
                  <hr />
                </div>
                <span className={classes.paddingLeft16Small}>
                  Copyright &copy; {1900 + new Date().getYear()} , tecmow.com
                </span>
              </div>
            </div>
          </div>
        }
        <div className={classes.clearFix} />
      </div>
    </footer>
  );
}

Footer.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.oneOf(["dark", "white", "transparent"]),
  big: PropTypes.bool,
  content: PropTypes.node
};

export default withStyles(footerStyle)(Footer);
