import {
  title,
  cardTitle,
  coloredShadow,
  grayColor
} from "assets/jss/material-kit-pro-react.jsx";
import {successColor, whiteColor} from "assets/jss/material-kit-pro-react";

const sectionInterestedStyle = {
  title,
  cardTitle,
  coloredShadow,
  textCenter: {
    textAlign: "center"
  },
  section: {
    backgroundPosition: "50%",
    backgroundSize: "cover",
    padding: "70px 0"
  },
  description: {
    color: grayColor[0],
    "& a": {
      color: "#9c27b0"
    }
  },
  subText: {
    color: grayColor[3],
    "& a": {
      color: "#3C4858"
    }
  },
  titleCard: {
    "&, & a": {
      ...title,
      marginTop: ".625rem",
      marginBottom: "0.75rem",
      minHeight: "auto",
      background: "transparent",
      color: "#3C4858"
    }
  },
  opacity: {
    opacity: 0.6
  },
  garden: {
    position: 'relative',
    width: '300px',
    height: '200px',
    overflow: 'hidden',
    boxShadow: '0 5px 15px -8px rgba(0, 0, 0, 0.24), 0 8px 10px -5px rgba(0, 0, 0, 0.2)',
    borderRadius: '10px',
    transition: '0.2s linear',
    cursor: 'pointer',
    '&:hover': {
      boxShadow: 'rgb(105 133 107) 0px 6px 24px 1px, rgb(102 130 104) 0px 8px 10px -5px'
    },
  },
  fixContainer: {
    "@media only screen and (max-width: 1000px) and (min-width: 900px)": {
      display: 'flex !important',
      'flex-direction': 'row',
      'align-items': 'center',
      '& > div': {
        'max-width': '100%',
        'flex-basis': '100%'
      }
    }
  },
  gardenActive: {
    boxShadow: 'rgb(105 133 107) 0px 6px 24px 1px, rgb(102 130 104) 0px 8px 10px -5px'
  },
  gardenCard: {
    "@media only screen and (max-width: 1050px)": {
        display: 'flex',
        justifyContent: 'center'
    },
  },
    gardenCardBody: {
      "@media only screen and (max-width: 1050px)": {
          display: 'inline-block',
          flex: 'initial'
      },
    },
  gardenButtonSection: {
    textAlign: 'right', paddingBottom: '30px',
    "@media only screen and (max-width: 1050px)": {
      textAlign: 'center',
    },
  },
  fixedMobileButtons: {
    "@media only screen and (max-width: 960px)": {
      textAlign: 'center',
      position: 'fixed',
      bottom: '0',
      left: '0',
      width: '100%',
      margin: '0',
    },
  },
  price: {
    boxShadow: '0 5px 15px -8px rgba(0, 0, 0, 0.24), 0 8px 10px -5px rgba(0, 0, 0, 0.2)',
    transition: '0.2s linear',
    cursor: 'pointer',
    background: 'whitesmoke',
    '&:hover': {
      boxShadow: 'rgb(105 133 107) 0px 6px 24px 1px, rgb(102 130 104) 0px 8px 10px -5px'
    }
  },
  priceActive: {
    boxShadow: 'rgb(105 133 107) 0px 6px 24px 1px, rgb(102 130 104) 0px 8px 10px -5px'

  },
  imageGarden: {
    objectFit: 'fill',
    width: '100%',
  }
};

export default sectionInterestedStyle;
