import FormControl from "@material-ui/core/FormControl";
import TextField from "@material-ui/core/TextField";
import React from "react";

export const renderTextField = ({ label, input, meta: { touched, invalid, error }, ...custom}) => {
    return (
        <FormControl fullWidth variant="filled">
            <TextField
                label={label}
                placeholder={label}
                error={touched && invalid}
                helperText={touched && error}
                {...input}
                {...custom}
            />
        </FormControl>
    );
};
