import React from "react";
import FormHelperText from '@material-ui/core/FormHelperText';
import Chip from "@material-ui/core/Chip";
import AttachFileOutlinedIcon from '@material-ui/icons/AttachFileOutlined';
import Avatar from '@material-ui/core/Avatar';

const adaptFileEventToValue = delegate => e => delegate(e.target.files);

export const FileInput = ({input: { value: omitValue = '', onChange, onBlur, ...inputProps }, meta: omitMeta, ...props}) => {
    let {length, ...files} = omitValue;
    files = Object.values(files);
    function conv_size(b){
        const fsizekb = b / 1024,
        fsizemb = fsizekb / 1024,
        fsizegb = fsizemb / 1024,
        fsizetb = fsizegb / 1024;
        let fsize;

        if (fsizekb <= 1024) {
            fsize = fsizekb.toFixed(3) + ' kb';
        } else if (fsizekb >= 1024 && fsizemb <= 1024) {
            fsize = fsizemb.toFixed(2) + ' mb';
        } else if (fsizemb >= 1024 && fsizegb <= 1024) {
            fsize = fsizegb.toFixed(3) + ' gb';
        } else {
            fsize = fsizetb.toFixed(3) + ' tb';
        }
        return fsize;
    }

    return (
        <div>
            <input
                onChange={adaptFileEventToValue(onChange)}
                onBlur={adaptFileEventToValue(onBlur)}
                type="file"
                {...props.input}
                {...props}
            />
            {omitMeta && omitMeta.invalid && omitMeta.error && (
                <div>
                    <FormHelperText error={true}>
                        {omitMeta.error}
                    </FormHelperText>
                    <p></p>
                </div>
            )}
            {omitValue.length > 0 &&
            files.map((item, index) => (
                <div key={index}>
                    <Chip variant="outlined"
                          color="default"
                          avatar={<Avatar><AttachFileOutlinedIcon /></Avatar>}
                          label={
                              <div>
                                  <span className={props.textClass}>{item.name}</span> {' '}
                                  <small>{conv_size(item.size)}</small>
                              </div>
                          }
                    />
                    <hr/>
                </div>
            ))}
        </div>
    );
};
