export const required = value =>
  value || typeof value === "number" ? undefined : "Required";
const maxLength = max => value =>
  value && value.length > max ? `Invalid phone number, must be ${max} digits` : undefined;
export const maxLength11 = maxLength(11);

export const minLength = min => value =>
  value && value.length < min ? `Invalid phone number, must be ${min} digits` : undefined;
export const minLength10 = minLength(10);

export const minLengthText = min => value =>
    value && value.length < min ? `Must be ${min} characters or more` : undefined;
export const minLength2 = minLengthText(2)

export const phoneNumber = value =>
  value && !/^([0-9]{10})$/i.test(value)
    ? "Invalid phone number, must be 10 digits"
    : undefined;

export const postCode = value =>
    value && !/^(([gG][iI][rR] {0,}0[aA]{2})|(([aA][sS][cC][nN]|[sS][tT][hH][lL]|[tT][dD][cC][uU]|[bB][bB][nN][dD]|[bB][iI][qQ][qQ]|[fF][iI][qQ][qQ]|[pP][cC][rR][nN]|[sS][iI][qQ][qQ]|[iT][kK][cC][aA]) {0,}1[zZ]{2})|((([a-pr-uwyzA-PR-UWYZ][a-hk-yxA-HK-XY]?[0-9][0-9]?)|(([a-pr-uwyzA-PR-UWYZ][0-9][a-hjkstuwA-HJKSTUW])|([a-pr-uwyzA-PR-UWYZ][a-hk-yA-HK-Y][0-9][abehmnprv-yABEHMNPRV-Y]))) {0,}[0-9][abd-hjlnp-uw-zABD-HJLNP-UW-Z]{2}))$/i.test(value)
        ? "Postcode is not valid"
        : undefined;

const reducer = (accumulator, currentValue) => accumulator + currentValue; // sum all image.size

export const validateImageWeight = imageFile => {
    let {...files} = imageFile;
    files = Object.values(files);

    const maxWeight = 2048;
    if (files) {
        const arr = [];
        let sumSize = 0;
        files.forEach(item => {
            arr.push(item.size)
        })
        if (arr.length > 0) {
            sumSize = arr.reduce(reducer);
        }
        const imageFileKb = sumSize / 1024;
        if (imageFileKb > maxWeight) {
            return `Image size must be less or equal to ${(maxWeight / 1024).toFixed(2)} Mb`;
        }
    }
};