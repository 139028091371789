import {
  container,
  title,
  main,
  whiteColor,
  mainRaised,
  successColor,
  blackColor,
  grayColor
} from "assets/jss/material-kit-pro-react.jsx";



const landingPageStyle = {
  container: {
    color: whiteColor,
    ...container,
    zIndex: "2"
  },
  title: {
    ...title,
    display: "inline-block",
    position: "relative",
    marginTop: "30px",
    minHeight: "32px",
    color: whiteColor,
    textDecoration: "none"
  },
  subtitle: {
    fontSize: "1.313rem",
    maxWidth: "500px",
    margin: "10px auto 0"
  },
  main: {
    ...main
  },
  mainRaised: {
    ...mainRaised
  },
  block: {
    color: "inherit",
    padding: "0.9375rem",
    fontWeight: "500",
    fontSize: "12px",
    textTransform: "uppercase",
    borderRadius: "3px",
    textDecoration: "none",
    position: "relative",
    display: "block"
  },
  inlineBlock: {
    display: "inline-block",
    padding: "0px",
    width: "auto"
  },
  list: {
    marginBottom: "0",
    padding: "0",
    marginTop: "0"
  },
  left: {
    float: "left!important",
    display: "block"
  },
  right: {
    padding: "15px 0",
    margin: "0",
    float: "right"
  },
  icon: {
    width: "18px",
    height: "18px",
    top: "3px",
    position: "relative"
  },
  positionRelative: {
    position: 'relative'
  },
  positionAbsolute: {
    position: 'absolute'
  },
  backroundWhite: {
    backgroundColor: 'white'
  },
  top10: {
    top: '10%'
  },
  left10: {
    left: '10%'
  },
  top35: {
    top: '35%',
    "@media (max-width: 768px)": {
      top: '20%',
    },
    "@media (max-width: 500px)": {
      top: '15%',
    },
    "@media (max-width: 450px)": {
      top: '10%',
    }
  },
  borderRadius50: {
    borderRadius: "50%"
  },
  horizontal40: {
    left: '40%',
    "@media (max-width: 768px)": {
      left: '20%',
    },
    "@media (max-width: 500px)": {
      left: '17%',
    },
    "@media (max-width: 450px)": {
      left: '30%',
    },
    // "@media (max-width: 500px)": {
    //   left: '25%',
    // },
    // "@media (max-width: 400px)": {
    //   left: '15%',
    // },
    // "@media (max-width: 350px)": {
    //   left: '10%',
    // },
  },
  font25: {
    fontSize: '25px'
  },
  smallScreenFont3vw: {
    "@media (max-width: 450px)": {
      fontSize: '3vw',
    }
  },
  smallScreenButtonTextStyle: {
    "@media (max-width: 450px)": {
      fontSize: '3vw',
      marginBottom: '4px',
      paddingBottom: '5px',
      paddingTop: '5px'
    }
  },
  fontWeight400: {
    fontWeight: '400'
  },
  fontWeight800: {
    fontWeight: '800'
  },
  landingTitle: {    
    "@media (max-width: 768px)": {
      fontSize: '4vh'      
    },
    "@media (max-width: 500px)": {
      fontSize: '4vh'
    },
    "@media (max-width: 450px)": {
      fontSize: '4vh',
      letterSpacing: '1vh'
    },  
    marginTop: '60px',  
    fontSize: '7.3vh',
    letterSpacing: '2vh',
    fontFamily: 'BebasNeueBold'
  },
  fontGreen: {
    color: successColor[0]
  },
  fontBlack: {
    color: blackColor
  },
  fontGrey: {
    color: grayColor[0]
  },
  fontHelvetica: {
    font:"12px Helvetica sans-serif",
    color: '#999',
    textDecoration: "none"
  },
  whiteBackground: {
    backgroundColor: whiteColor
  },
  widthMaxContent: {
    width: 'max-content'
  },
  textCenter: {
    textAlign: 'center'
  },
  displayInlineBlock: {
      display: 'inline-block'
  },
  displayBlock: {
    "@media (max-width: 960px)": {
      display: 'block !important',
      textAlign: 'center'
    },
  },
  borderBlack: {
    border: "1px solid black"
  },
  outlineBlack: {
    outline: "1px solid black"
  },
  justifyContentCenter: {
    display: "flex",
    justifyContent: "center",
    "@media (max-width: 960px)": {
      marginLeft: "20px",
    }
  },
  marginTop50: {
    marginTop: "50px",
    "@media (max-width: 960px)": {
      marginTop: "20px",
    }
  },
  marginTop70: {
    marginTop: "70px",
    "@media (max-width: 960px)": {
      marginTop: "20px",
    }
  },
  marginBottom50: {
    marginBottom: "50px",
    "@media (max-width: 960px)": {
      marginBottom: "20px",
    }
  },
  marginBottom35: {
    marginBottom: "35px"
  },
  font30: {
    fontSize: "30px"
  },
  marginTop15: {
    marginTop: "15px"
  },
  marginBottom15: {
    marginBottom: "15px"
  },
  paddingAround10: {
    padding: "10px"
  },
  width30: {
    width: "30px"
  },
  height30: {
    height: "30px"
  },
  logo: {
    height: "150px",
    width: "auto"
  },
  paddingLeft10: {
    paddingLeft: "10px"
  },
  paddingRight5: {
    paddingRight: "5px"
  },
  paddingTop4: {
    paddingTop: "4px"
  },
  paddingNull: {
    padding: '0'
  },
  fontSize15: {
    fontSize: "15px"
  },
  maxWidth100: {
    maxWidth: "100%"
  },
  width100Percent: {
    width: "100%"
  },
  width48Percent: {
    width: "48%"
  },
  verticalAlignTop: {
    verticalAlign: "top"
  },
  displayFlex: {
    display: "flex"
  },
  alignItemsCenter: {
    alignItems: "center"
  },
  font20 : {
    fontSize: "20px"
  },
  textAlignEnd: {
    textAlign: "end",
    "@media (max-width: 960px)": {
      textAlign: 'center'
    },
  },
  marginAuto: {
    margin: "auto"
  },
  marginBottom_30: {
    marginBottom: "30px"
  },
  font16: {
    fontSize: "16px"
  },
  verticalAlignBottom: {
    verticalAlign: "bottom"
  },
  responsiveFont: {
    "@media (max-width: 1200px)": {
      fontSize: '11px'
    },
    "@media (max-width: 1024px)": {
      fontSize: '10px'
    },
    "@media (max-width: 960px)": {
      fontSize: '16px'
    },
    "@media (max-width: 440px)": {
      fontSize: '12px'
    },
    "@media (max-width: 400px)": {
      fontSize: '10px'
    },
    "@media (max-width: 370px)": {
      fontSize: '9px'
    },
  },
  headerStyle: {
    color: "#3C4858",
    marginTop: "30px",
    minHeight: "32px",
    fontWeight: "700",
    fontFamily: "'Roboto Slab', 'Times New Roman', serif",
    marginBottom: "1rem",
    textDecoration: "none"
  }
};

export default landingPageStyle;