import React from "react";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

// @material-ui/icons
import Chat from "@material-ui/icons/Chat";

import VerifiedUser from "@material-ui/icons/VerifiedUser";
import Fingerprint from "@material-ui/icons/Fingerprint";
// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import InfoArea from "components/InfoArea/InfoArea.jsx";

import productStyle from "assets/jss/material-kit-pro-react/views/landingPageSections/productStyle.jsx";

import lawnMowerImage from "assets/img/sections/Services/lawn-mower.svg"
import femaleHedgeCuttingImage from "assets/img/sections/Services/female-hedge-cutting.svg"
import femaleSecateursImage from "assets/img/sections/Services/female-secateurs.svg"
import flowerBorderMystuffiumImage from "assets/img/sections/Services/flower-border-mystuffium.svg"
import chainsawImage from "assets/img/sections/Services/chainsaw.svg"



class SectionProduct extends React.Component {
  render() {
    const { classes } = this.props;
    return (
      <div className={classes.section + " "
        + classes.paddingTop20Small
      }>
        <GridContainer justify="center">
          <GridItem xs={12} sm={8} md={8}>
            <h2 className={classes.title}>Services</h2>
          </GridItem>
        </GridContainer>
        <div>
          <GridContainer>
            <GridItem xs={12} sm={4} md={4}>
              <InfoArea
                title="Lawn Mowing"
                description="
                Our staff know about lawn treatment.  How often to cut it, the ideal length and how to
                get it looking in great shape
                "
                icon={Chat}
                iconColor="info"
                vertical
                imgSrc={lawnMowerImage}
                className={
                  classes.paddingTop20Small
                }
              />
            </GridItem>
            <GridItem xs={12} sm={4} md={4}>
              <InfoArea
                title="Maintaining herbaceous borders"
                description="
                Need somebody with expertise in horticulture?
                "
                icon={VerifiedUser}
                iconColor="success"
                vertical
                imgSrc={flowerBorderMystuffiumImage}
                className={
                  classes.paddingTop20Small
                }
              />
            </GridItem>
            <GridItem xs={12} sm={4} md={4}>
              <InfoArea
                title="Pruning"
                description="
                Need somebody with some good plant knowledge to take care your favourite shrubs and trees
                "
                icon={Fingerprint}
                iconColor="danger"
                vertical
                imgSrc={femaleSecateursImage}
                className={
                  classes.paddingTop20Small
                }
              />
            </GridItem>
            <GridItem xs={12} sm={4} md={4}>
              <InfoArea
                  title="Hedge Trimming"
                  description="
                  Have you a got a tall or wide hedge?
                  "
                  icon={Fingerprint}
                  iconColor="danger"
                  vertical
                  imgSrc={femaleHedgeCuttingImage}
                  className={
                    classes.paddingTop20Small
                  }
              />
            </GridItem>
            <GridItem xs={12} sm={4} md={4}>
              <InfoArea
                  title="Garden Clearance"
                  description="
                  Got a jungle?  Allow our gardeners to tame it and get back in control
                  "
                  icon={Fingerprint}
                  iconColor="danger"
                  vertical
                  imgSrc={chainsawImage}
                  className={
                    classes.paddingTop20Small
                  }
              />
            </GridItem>
          </GridContainer>
        </div>
      </div>
    );
  }
}

export default withStyles(productStyle)(SectionProduct);
