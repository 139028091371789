import React from "react";
import { Provider } from "react-redux";
import { render } from "react-dom";
import { createBrowserHistory } from "history";
import {
  Router,
  Route,
  Switch,
  Redirect
} from "react-router-dom";
import "assets/scss/material-kit-pro-react.scss?v=1.3.0";
import LandingPage from "views/LandingPage/LandingPage.jsx";
import AboutUsPage from "views/AboutUsPage/AboutUsPage.jsx";
import TagManager from "react-gtm-module";
import { store } from "./helpers";
import PricingPage from "./views/PricingPage/PricingPage";

const tagManagerArgs = {
  gtmId: "GTM-NGXMQNG"
};

TagManager.initialize(tagManagerArgs);

const hist = createBrowserHistory();
const rootElement = document.getElementById("root");
render(
  <Provider store={store}>
    <Router history={hist}>
      <Switch>
        <Route exact path="/">
          <LandingPage />
        </Route>
        <Route path="/faq">
          <AboutUsPage />
        </Route>
        <Route path="/pricing">
          <PricingPage />
        </Route>
        <Route path="*">
          <Redirect to="/" />
        </Route>
      </Switch>
    </Router>
  </Provider>,
  rootElement
);
