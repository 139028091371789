import React from "react";
import { Field, reduxForm } from "redux-form";
import {
    minLength10, minLength2, postCode,
    required, validateImageWeight
} from "../../validate/validate";

import InputLabel from "@material-ui/core/InputLabel";
import Button from "../../components/CustomButtons/Button";
import Icon from '@material-ui/core/Icon';
import { FileInput } from "./InputFile";
import {renderTextField} from "./TextField";

const Form = props => {
  const { handleSubmit, pristine, submitting, propsClasses, backToPricing } = props;
  return (
    <form onSubmit={handleSubmit} className={propsClasses.formBase} encType="multipart/form-data">
      <div className={propsClasses.formInput}>
        <Field
          id="inputName"
          name="name"
          component={renderTextField}
          type="text"
          placeholder="Name"
          validate={[required, minLength2]}
        />
      </div>
      <div className={propsClasses.formInput}>
          <Field
              id="inputHouseNumber"
              name="house"
              component={renderTextField}
              type="text"
              placeholder="House number"
              validate={[required]}
          />
      </div>
      <div className={propsClasses.formInput}>
        <Field
          id="inputPostcode"
          name="postcode"
          component={renderTextField}
          type="text"
          placeholder="Postcode"
          validate={[required, postCode]}
        />
      </div>
      <div className={propsClasses.formInput}>
        <Field
          id="inputNumber"
          name="phone"
          component={renderTextField}
          type="number"
          placeholder="Phone number"
          validate={[required, minLength10]}
        />
      </div>
      <div className={propsClasses.formInput}>
        <Field
          name="description"
          component={renderTextField}
          type="text"
          placeholder="Describe the type of work you'd like to have done"
          multiline
          rows={4}
          validate={[required]}
        />
      </div>
        <div className={propsClasses.formInput}>
            <InputLabel htmlFor="inputDescription">Image</InputLabel>
            <Field
                className={propsClasses.formFileInput}
                textClass={propsClasses.filename}
                name="image"
                component={FileInput}
                type="file"
                validate={[validateImageWeight]}
                multiple
            />
            <div className={propsClasses.formFile}>
                <span className={propsClasses.formFileText}>
                    <Icon color="action" fontSize='small' className={propsClasses.iconSearch}>
                        insert_photo
                    </Icon>
                    Select a file
                </span>
            </div>
        </div>
      { !backToPricing && <div className={propsClasses.formBtn}>
          <Button
            color="primary"
            round
            type="submit"
            disabled={pristine || submitting}
          >
              Send me a quote
          </Button>
        </div> 
      }
      { backToPricing && <div className={propsClasses.formBtnFlex}>
          <Button
            color="primary"
            round
            onClick={() => backToPricing()}
          >
              Prev
          </Button>
          <Button
            color="primary"
            round
            type="submit"
            disabled={pristine || submitting}
          >
              Send me a quote
          </Button>
        </div> 
      }
    </form>
  );
};

const contactForm = reduxForm({
    form: "contact"
})(Form)

export default contactForm;
