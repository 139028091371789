import React from "react";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// core components
import GridContainer from "components/Grid/GridContainer.jsx";

import Accordion from '../../../components/Accordion/Accordion'

import servicesStyle from "assets/jss/material-kit-pro-react/views/aboutUsSections/servicesStyle.jsx";

function SectionServices(props) {
  const { classes } = props;
  return (
    <div className={classes.services}>
      <GridContainer>
      <Accordion
        active={false}
        collapses={[
          {
            title: "What if the gardener doesn’t show up?",
            content:
              <p>
                Please call us straight away so that we can resolve the issue for you
              </p> 
          },
          {
            title: "What if I’m not happy with the work?",
            content:
              <p>
                In the first instance, explain to the gardener what you would like to be done to rectify the issue.
                Please allow them to resolve the issue if they will cooperate as this is the best solution for all parties.
                If you don’t trust them to resolve it then please call the us and we will make a suggestion to resolve
                it by either sending somebody else round. You are backed by a money back guarantee but please try
                other available methods first as we’d rather you be happy with the result. If there’s damage that’s
                been done then this will need to be paid from the contractor’s public liability insurance.
              </p>
          },
          {
            title: "What is the cost?",
            content:
              <p>
                The costs are currently set at £25 for the first hour and £20 there after with an additional 10% service fee.
              </p>
          },
          {
            title: "How many hours should I book?",
            content:              
              <div>
                <p>
                  We’ve created a basic table as a suggestion. This assumes that the garden has good access via a side
                  gate (equipment and waste doesn’t need to come through the house) and that parking is available
                  immediately outside your property.
                </p>
                <table style={{textAlign:"center", marginTop: '10px', marginRight: '10px', marginBottom: '10px', borderCollapse: 'collapse'}}>
                  <tr>
                    <td style={{border: '1px solid black'}}></td>
                    <td style={{width: 75, border: '1px solid black'}}>Small (&lt;100sqm)</td>                    
                    <td style={{width: 75, border: '1px solid black'}}>Med (100-300sqm)</td>
                    <td style={{width: 75, border: '1px solid black'}}>Large (&gt;300sqm)</td>
                  </tr>
                  <tr>
                    <td style={{ border: '1px solid black'}}>less than 2 weeks's growth</td>
                    <td style={{width: 75, border: '1px solid black'}}>2</td>
                    <td style={{width: 75, border: '1px solid black'}}>3</td>
                    <td style={{width: 75, border: '1px solid black'}}>4</td>
                  </tr>
                  <tr>
                    <td style={{ border: '1px solid black'}}>2- 4 weeks growth</td>
                    <td style={{width: 75, border: '1px solid black'}}>3</td>
                    <td style={{width: 75, border: '1px solid black'}}>5</td>
                    <td style={{width: 75, border: '1px solid black'}}>8</td>
                  </tr>
                  <tr>
                    <td style={{ border: '1px solid black'}}>over a month's growth</td>
                    <td style={{width: 75, border: '1px solid black'}}>5</td>
                    <td style={{width: 75, border: '1px solid black'}}>8</td>
                    <td style={{width: 75, border: '1px solid black'}}>16</td>
                  </tr>
                </table>                
                <p>
                  It’s very hard to be precise without seeing it and that’s why we recommend booking the first
                  visit based on the table. You can then discuss your requirements with your gardener when they’ve
                  seen the job.
                </p>
              </div>
          },
          {
            title: "How do I measure my garden?",
            content:
              <>
                <p>
                  The easiest way is to use google maps.
                </p>
                <hr/>
                  <a href="https://support.google.com/maps/answer/1628031?co=GENIE.Platform%3DDesktop&hl=en&oco=1">Maps</a> 
                <hr/>
                <p>
                  Join the outside edges together and it will give you an area measurement.
                  A lower tech way is to measure it using a long tape measure or distance measuring wheel. It doesn’t
                  need to be that accurate so just do large paces and count them if that’s all you have. Approximate
                  the area by multiplying the width by the length.
                </p>  
              </>          
          },
          {
            title: "What if I book too many hours?",
            content:
              <p>
                If the gardener has been so productive that they’ve finished before your hours are up then you can
                claim the hours on another visit. If it’s due to the gardener working very effectively or particularly
                hard consider whether they deserve the bonus. You had allocated the money anyway and they’ve
                delivered what you asked for.
              </p>            
          },
          {
            title: "Can I leave a tip?",
            content:
              <p>
                Sometimes you can see that a gardener has gone over and above the call of duty to delver a great
                job. Toiling in intense heat or battling through poor conditions. Sometimes we feel we’re getting
                such great value that the gardener deserves more. We’re working on being able to add this if there’s
                a demand. In this instance you’re welcome to give the gardener a cash tip or book an hour’s
                overtime.
              </p>            
          },
          {
            title: "What if I need to book more hours?",
            content:
              <p>
                If the gardener has time on the day then they can work the hours and put in an overtime request
                which you will have to approve.
              </p>            
          },
          {
            title: "What if the gardener asks for money?",
            content:
              <p>
                Report it to us immediately. The gardener should in no circumstance ask for money.
              </p>            
          },
          {
            title: "What if I want to book the gardener regularly?",
            content:
              <p>
                Once a gardener has been to your house for at least one visit we can set up a contract for your
                garden maintenance. We need to get somebody to do an on site assessment of your garden so that
                they can estimate the time it will take to maintain every month. This can be paid monthly and
                spread over the year.
              </p>            
          },
          {
            title: "How do I pay?",
            content:
              <p>
                You pay using the Pay Pal checkout once you’ve booked the number of hours you need. You’re
                backed by our 100% money back guarantee if you’re not satisfied with the service
              </p>            
          },
          {
            title: "I’m inviting somebody to my house. How do I know they are trustworthy?",
            content:
              <p>
                All gardeners are reference checked as well as DBS criminal background check. They are all
                interviewed by one of our senior management too.
              </p>            
          },
          {
            title: "What qualifications does the gardener have?",
            content:
              <>
                <p>
                  All of our gardeners must have one of the following:
                </p>  
                <ul>
                  <li>Be studying for a horticultural qualification</li>
                  <li>Have a relevant city and guilds qualification for land based industries</li>
                  <li>Been a time-served gardener for over a year</li>
                  <li>Have experience working in a botanical garden, plant nursery, groundskeeping setting</li>
                  <li>Created and maintained a beautiful garden as a hobby</li>
                </ul> 
              </>         
          },
          {
            title: "What tools does the gardener have?",
            content:
              <p>
                Our gardeners are fully equipped with the tools they need to do the job without needing to access
                your electricity.
              </p>            
          },
          {
            title: "What do I do about garden waste?",
            content:
              <p>
                Call the office and we’ll send an invoice for the amount to be collected. This can either be done as a
                price per bulk bag or if you have a large amount, by half or full truckload. We’ll send an invoice and
                collect the waste once it’s paid. Please leave the waste somewhere it is easy to access. Loading time
                greater than 15mins for bulk bag or 30mins for a truckload is chargeable at £35 p/h
              </p>            
          },
          {
            title: "What if the Gardener has to pay for parking?",
            content:
              <p>
                We ask that you either have for them a permit or that you pay for their parking. If parking is always
                problematic and they won’t need a van, please call the office for a specific arrangement
              </p>            
          },
          {
            title: "Can you cut high hedges?",
            content:
              <p>
                We will need to come out and see the job first to make sure that no further planning such as traffic
                management or access equipment are needed. If it can be done safely from ladders then we can do
                it then and there if you’ve booked some hours
              </p>            
          },
          {
            title: "Why can’t you just come out and give me a quote?",
            content:
              <p>
                Quotes are time consuming and often go unrewarded. Their cost is justified for large projects but
                we don’t think it’s sensible to get three quotes for a small job. You will find that many times
                contractors don’t turn up to quote or never get back to you anyway. It’s wasted time for customers
                and contractors. So long as people work effectively you’re never getting ripped off. 
              </p>            
          },
          {
            title: "What if I think the gardener is taking too long?",
            content:
              <p>
                We’re confident that you won’t see this happen. Our contractors are efficient with their time and
                we’ve selected them on the basis of their previous customer service. The first hour being more
                expensive also acts as an incentive for them to get to other jobs if they can. They get more money if
                they’re efficient so it doesn’t pay for them to hang out your job. There’s always somebody to
                answer from customer service if you definitely need a new gardner.
              </p>            
          },
          {
            title: "What if I think the gardener is rushing?",
            content:
              <p>
                We’re confident you won’t think this either. We carefully manage their schedules so that they aren’t
                rushed off their feet and they can provide with the top service we believe you deserve. Contact
                customer services if you think the gardener isn’t taking enough care.
              </p>            
          },
      
        ]}
      />
          
      </GridContainer>
    </div>
  );
}

export default withStyles(servicesStyle)(SectionServices);
