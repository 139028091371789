import React from "react";
// core components
// @material-ui/icons
import TrendingUp from "@material-ui/icons/TrendingUp";
// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import InfoArea from "components/InfoArea/InfoArea.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from '@material-ui/core/CardHeader';
import CardMedia from '@material-ui/core/CardMedia';
import Card from "components/Card/CardBody.jsx";
import Avatar from '@material-ui/core/Avatar';

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// @material-ui icons
import CardMembership from "@material-ui/icons/CardMembership";
import CardGiftCard from "@material-ui/icons/CardGiftcard";
import AttachMoney from "@material-ui/icons/AttachMoney";
import QuestionAnswer from "@material-ui/icons/QuestionAnswer";


import featuresStyle from "assets/jss/material-kit-pro-react/views/pricingSections/featuresStyle.jsx";
import Info from "components/Typography/Info.jsx";
import Success from "components/Typography/Success.jsx";
import Danger from "components/Typography/Danger.jsx";

import blog5 from "assets/img/examples/blog5.jpg";
import blog6 from "assets/img/examples/blog6.jpg";
import bg5 from "assets/img/garden.jpg";
import sectionInterestedStyle from "assets/jss/material-kit-pro-react/views/blogPostsSections/sectionInterestedStyle.jsx";
import gardenStyle from "assets/jss/material-kit-pro-react/views/gardenSections/gardenStyle.jsx";
import Button from "components/CustomButtons/Button.jsx";

function SectionGardens(props) {
  const { classes, garden, gardens, chooseGarden, goToPricing } = props;
   return (
    <GridContainer className={classes.fixContainer}>
        { Object.keys(gardens).map( (indexGarden, i) => 
           <GridItem xs={12} sm={12} md={4} lg={4} xl={4}>
            <Card plain blog className={classes.gardenCard}>
              <CardBody plain className={classes.gardenCardBody}>
               <Info>
                  <h6>{gardens[indexGarden].name} ({gardens[indexGarden].description})</h6>
                </Info>
                <div onClick={() => { chooseGarden(indexGarden) }} className={
                  classes.garden + " " + 
                  (garden && (garden === indexGarden ? classes.gardenActive : classes.opacity))
                  }>
                  <img src={gardens[indexGarden].src} className={classes.imageGarden} />
                </div>
                <Info>
                </Info>
              </CardBody>
            </Card>
         </GridItem>
        )}
        <GridItem className={classes.gardenButtonSection + " " + classes.fixedMobileButtons} xs={12} sm={12} md={12} lg={12} xl={12}>
              <Button disabled={!garden} onClick={() => goToPricing()} color="primary" round>Next</Button>
      </GridItem>
    </GridContainer>
      
  );
}

export default withStyles(sectionInterestedStyle)(SectionGardens);
