import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Card from "components/Card/Card.jsx";
import CardAvatar from "components/Card/CardAvatar.jsx";
import CardBody from "components/Card/CardBody.jsx";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import teamStyle from "assets/jss/material-kit-pro-react/views/aboutUsSections/teamStyle.jsx";

import FaceTom from "assets/img/Gardeners/image.png";
import FaceSteve from "assets/img/Gardeners/Steve_Bedford.jpg";
import FaceJoe from "assets/img/Gardeners/Joe.jpg";
import StarIcon from "assets/img/star.svg";

function SectionTeam(props) {
  const { classes } = props;
  return (
    <div className={classes.team}>
      <GridContainer>
        <GridItem
          md={8}
          sm={8}
          className={classNames(
            classes.mrAuto,
            classes.mlAuto,
            classes.textCenter
          )}
        >
          <h2 className={classes.title}>Our Top Gardeners</h2>
        </GridItem>
      </GridContainer>
      <GridContainer>
        <GridItem md={3} sm={4}>
          <Card profile plain>
            <CardAvatar profile plain>
              <a href="#">
                <img src={FaceTom} alt="Tom" className={classes.img} />
              </a>
            </CardAvatar>
            <CardBody plain>
              <p className={classes.titleBackground}>Tom</p>
              <div className={classes.starBlock}>
                <img src={StarIcon} alt="star"/>
                <img src={StarIcon} alt="star"/>
                <img src={StarIcon} alt="star"/>
                <img src={StarIcon} alt="star"/>
                <img src={StarIcon} alt="star"/>
              </div>
              <h4 className={classes.textLocate}>
                Birmingham
              </h4>
              <p className={classes.textDescrib}>
                I would recommend Tom for all levels of gardening needs, but most importantly for a professional knowledge-based service.
              </p>
            </CardBody>
          </Card>
        </GridItem>
        <GridItem md={3} sm={4}>
          <Card profile plain>
            <CardAvatar profile plain>
              <a href="#">
                <img src={FaceSteve} alt="Steve" className={classes.img} />
              </a>
            </CardAvatar>
            <CardBody plain>
              <p className={classes.titleBackground}>Steve</p>
              <div className={classes.starBlock}>
                <img src={StarIcon} alt="star"/>
                <img src={StarIcon} alt="star"/>
                <img src={StarIcon} alt="star"/>
                <img src={StarIcon} alt="star"/>
                <img src={StarIcon} alt="star"/>
              </div>
              <h4 className={classes.textLocate}>
                Birmingham
              </h4>
              <p className={classes.textDescrib}>
                Excellent service from Steve. He is reliable, hard working, knowledgeable and full of good ideas and suggestions to get our garden into good order.
              </p>
            </CardBody>
          </Card>
        </GridItem>
        <GridItem md={3} sm={4}>
          <Card profile plain>
            <CardAvatar profile plain>
              <a href="#">
                <img src={FaceJoe} alt="Joe" className={classes.img} />
              </a>
            </CardAvatar>
            <CardBody plain>
              <p className={classes.titleBackground}>Joe</p>
              <div className={classes.starBlock}>
                <img src={StarIcon} alt="star"/>
                <img src={StarIcon} alt="star"/>
                <img src={StarIcon} alt="star"/>
                <img src={StarIcon} alt="star"/>
                <img src={StarIcon} alt="star"/>
              </div>
              <h4 className={classes.textLocate}>
                Birmingham
              </h4>
              <p className={classes.textDescrib}>
                I'm getting so many compliments on my front garden since Joe has been.
              </p>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    </div>
  );
}

export default withStyles(teamStyle)(SectionTeam);
